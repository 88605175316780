import { useState } from "react";
import Icons from "../../../Assets/Icons";
import { isInputValueValid } from "../../../utils/commonFiles";

const PercentageCalc = () => {
    const [percentage, setPercentage] = useState("");
    const [baseValue, setBaseValue] = useState("");
    const [result, setResult] = useState("");
    const [steps, setSteps] = useState("");

    const handleCalculate = () => {
        if (percentage && baseValue) {
            const calculatedResult = (parseFloat(percentage) / 100) * parseFloat(baseValue);
            setResult(calculatedResult.toFixed(2));
            setSteps(`${percentage}% of ${baseValue} = ${parseFloat(percentage) / 100} × ${baseValue} = ${calculatedResult.toFixed(2)}`);
        } else {
            alert("Please provide values for both percentage and base value.");
        }
    };

    const handleClear = () => {
        setPercentage("");
        setBaseValue("");
        setResult("");
        setSteps("");
    };
    return (
        <>
            <div>
                <h1 className="primary-font mt-0 mb-1">Percentage Calculator</h1>
                <div className="calculate-percentage-label">
                    Please provide any two values from the options below and click the "calculate" button to determine the third value.
                </div>
                <div className="calculate-card">
                    <div className="calculate-row">
                        <input
                            type="text"
                            className="ad-percentage"
                            placeholder="%"
                            value={percentage}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (isInputValueValid(value)) {
                                    setPercentage(value)
                                }
                            }}
                        />
                        <div className="">
                            <p>Of</p>
                        </div>
                        <input
                            type="text"
                            placeholder="Base Value"
                            value={baseValue}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (isInputValueValid(value)) {
                                    setBaseValue(value)
                                }
                            }}
                        />
                        <div className="">
                            <p>=</p>
                        </div>
                        <input type="text" placeholder="Result" value={result} readOnly />
                    </div>
                    <div className="calculate-btns">
                        <button className="btn-theme" onClick={handleCalculate}>
                            Calculate
                        </button>
                        <button className="btn-theme-outline" onClick={handleClear}>
                            Clear
                        </button>
                    </div>
                </div>
                {result && (
                    <div className="calculate-card">
                        <div className="result-box-contin">
                            <div className="result-box">Result: {result}</div>
                            <p>{percentage}% of {baseValue} = {result}</p>
                            <div className="result-share">
                                <div className="">
                                    <p className="mt-0 mb-0">Steps:</p>
                                    <p className="mb-0 mt-0">{steps}</p>
                                </div>
                                {/* Replace with your share icon component */}
                                {Icons.ShareIcon}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
export default PercentageCalc