import React, { useState } from "react";
import Icons from "../../../Assets/Icons";
import { isInputValueValid } from "../../../utils/commonFiles";

const PercentageDifferenceCalc = () => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [resultPercentageDifference, setResultPercentageDifference] = useState("");
    const [resultPercentageDecrease, setResultPercentageDecrease] = useState("");
    const [stepsPercentageDifference, setStepsPercentageDifference] = useState("");
    const [stepsPercentageDecrease, setStepsPercentageDecrease] = useState("");

    const handleCalculate = () => {
        if (value1 && value2) {
            const val1 = parseFloat(value1);
            const val2 = parseFloat(value2);

            // Percentage Difference Calculation
            const difference = Math.abs(val1 - val2);
            const avg = (val1 + val2) / 2;
            const percentageDifference = (difference / avg) * 100;

            // Steps for Percentage Difference
            setStepsPercentageDifference(`
        Difference of ${val1} and ${val2} = |${val1} - ${val2}| / (${val1} + ${val2}) / 2
        = ${difference.toFixed(2)} / ${avg.toFixed(2)}
        = ${(percentageDifference).toFixed(6)}%
      `);

            setResultPercentageDifference(
                `Difference of ${val1} and ${val2} are ${percentageDifference.toFixed(6)}%`
            );

            // Percentage Decrease Calculation
            const percentageDecrease = ((val1 - val2) / val1) * 100;

            // Steps for Percentage Decrease
            setStepsPercentageDecrease(`
        Percentage of decrease = |${val1} - ${val2}| / ${val1}
        = ${difference.toFixed(2)} / ${val1.toFixed(2)}
        = ${(percentageDecrease).toFixed(6)}%
      `);

            setResultPercentageDecrease(
                `${val2} is a ${Math.abs(percentageDecrease.toFixed(6))}% ${percentageDecrease < 0 ? "increase" : "decrease"
                } of ${val1}.`
            );
        } else {
            setResultPercentageDifference("Please enter both values!");
            setStepsPercentageDifference("");
            setResultPercentageDecrease("");
            setStepsPercentageDecrease("");
        }
    };

    const handleClear = () => {
        setValue1("");
        setValue2("");
        setResultPercentageDifference("");
        setStepsPercentageDifference("");
        setResultPercentageDecrease("");
        setStepsPercentageDecrease("");
    };

    return (
        <div>
            <h2 className="primary-font">Percentage Difference Calculator</h2>
            <div className="calculate-card">
                <div className="calculate-row">
                    <div>
                        <p>Value 1</p>
                    </div>
                    <input
                        type="text"
                        value={value1}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setValue1(value)
                            }
                        }}
                    />
                </div>
                <div className="calculate-row">
                    <div>
                        <p>Value 2</p>
                    </div>
                    <input
                        type="text"
                        value={value2}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setValue2(value)
                            }
                        }}
                    />
                </div>
                <div className="calculate-btns">
                    <button className="btn-theme" onClick={handleCalculate}>
                        Calculate
                    </button>
                    <button className="btn-theme-outline" onClick={handleClear}>
                        Clear
                    </button>
                </div>
            </div>
            {(resultPercentageDifference || resultPercentageDecrease) && (
                <div className="calculate-card">
                    <div className="result-box-contin">
                        {/* Result for Percentage Difference */}
                        <div className="result-box">Result: {resultPercentageDifference}</div>
                        <p>{resultPercentageDifference}</p>
                        <div className="">
                            <p className="mt-0 mb-0">Steps:</p>
                            <p className="mb-0 mt-0">{stepsPercentageDifference}</p>
                        </div>

                        {/* Result for Percentage Decrease */}
                        <p>{resultPercentageDecrease}</p>
                        <div className="">
                            <p className="mt-0 mb-0">Steps:</p>
                            <p className="mb-0 mt-0">{stepsPercentageDecrease}</p>
                        </div>

                        <div className="result-share">
                            {Icons.ShareIcon}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PercentageDifferenceCalc;
