import React, { useState } from "react";
import Layout from "../component/Layout";
import Icons from "../Assets/Icons";
import SEO from "../component/SEO";
import { isInputValueValid } from "../utils/commonFiles";

const CustomSVG = ({ angle }) => {
  return (
    <svg
      width="300px"
      height="163px"
      viewBox="0 0 300 163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(18,18)" style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px' }}>
        <defs>
          <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="0"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7"></polygon>
          </marker>
          <path id="curvetxt1" d="M-4 140 A140 140, 0, 0, 1, 284 140" style={{ fill: 'none' }}></path>
          <path id="curvetxt2" d="M33 43.6 A140 140, 0, 0, 1, 280 140"></path>
          <path id="curvetxt3" d="M95 3 A140 140, 0, 0, 1, 284 140"></path>
          <path id="curvetxt4" d="M235.4 33 A140 140, 0, 0, 1, 284 140"></path>
        </defs>
        <path
          d="M0 140 A140 140, 0, 0, 1, 6.9 96.7 L140 140 Z"
          fill="#bc2020"
        ></path>
        <path
          d="M6.9 96.7 A140 140, 0, 0, 1, 12.1 83.1 L140 140 Z"
          fill="#d38888"
        ></path>
        <path
          d="M12.1 83.1 A140 140, 0, 0, 1, 22.6 63.8 L140 140 Z"
          fill="#ffe400"
        ></path>
        <path
          d="M22.6 63.8 A140 140, 0, 0, 1, 96.7 6.9 L140 140 Z"
          fill="#008137"
        ></path>
        <path
          d="M96.7 6.9 A140 140, 0, 0, 1, 169.1 3.1 L140 140 Z"
          fill="#ffe400"
        ></path>
        <path
          d="M169.1 3.1 A140 140, 0, 0, 1, 233.7 36 L140 140 Z"
          fill="#d38888"
        ></path>
        <path
          d="M233.7 36 A140 140, 0, 0, 1, 273.1 96.7 L140 140 Z"
          fill="#bc2020"
        ></path>
        <path
          d="M273.1 96.7 A140 140, 0, 0, 1, 280 140 L140 140 Z"
          fill="#8a0101"
        ></path>
        <path
          d="M45 140 A90 90, 0, 0, 1, 230 140 Z"
          fill="#fff"
        ></path>
        <circle cx="140" cy="140" r="5" fill="#666"></circle>
        <g style={{ paintOrder: 'stroke', stroke: '#fff', strokeWidth: '2px' }}>
          <text x="25" y="111" transform="rotate(-72, 25, 111)">16</text>
          <text x="30" y="96" transform="rotate(-66, 30, 96)">17</text>
          <text x="35" y="83" transform="rotate(-57, 35, 83)">18.5</text>
          <text x="97" y="29" transform="rotate(-18, 97, 29)">25</text>
          <text x="157" y="20" transform="rotate(12, 157, 20)">30</text>
          <text x="214" y="45" transform="rotate(42, 214, 45)">35</text>
          <text x="252" y="95" transform="rotate(72, 252, 95)">40</text>
        </g>
        <g style={{ fontSize: '14px' }}>
          <text>
            <textPath href="#curvetxt1">Underweight</textPath>
          </text>
          <text>
            <textPath href="#curvetxt2">Normal</textPath>
          </text>
          <text>
            <textPath href="#curvetxt3">Overweight</textPath>
          </text>
          <text>
            <textPath href="#curvetxt4">Obesity</textPath>
          </text>
        </g>

        {/* { Dynamically rotate the arrow based on angle } */}
        <line
          x1="140"
          y1="140"
          x2="140" // The x-coordinate of the line end stays the same (at the center of the circle)
          y2="50" // You can adjust this if needed
          stroke="#666"
          strokeWidth="2"
          markerEnd="url(#arrowhead)"
          transform={`rotate(${angle}, 140, 140)`} // Apply rotation based on angle
        ></line>
      </g>
    </svg>
  );
};

export default function BMICalculator() {
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("Male");
  const [hightMesurement, setHightMesurement] = useState(0);
  const [weightMesurement, setWeightMesurement] = useState(0);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState(null);
  const [angle, setAngle] = useState(-88);
  const [result, setResult] = useState({})
  const [error, setError] = useState({
    age: '',
    height: '',
    weight: ''
  })

  const handleAgeInput = (event) => {
    const { value } = event.target;
    if (isInputValueValid(value)) {
      setAge(value)
    }
  }


  function calculateBMIValues(height, weight) {
    // Healthy BMI range
    const minBMI = 18.5;
    const maxBMI = 25;

    // BMI
    const bmi = weight / (height ** 2);

    // Healthy weight range
    const minWeight = minBMI * (height ** 2);
    const maxWeight = maxBMI * (height ** 2);

    // BMI Prime
    const bmiPrime = bmi / maxBMI;

    // Ponderal Index
    const ponderalIndex = weight / (height ** 3);

    return {
      bmi: bmi.toFixed(2),
      healthyWeightRange: `${minWeight.toFixed(1)} kg - ${maxWeight.toFixed(1)} kg`,
      bmiPrime: bmiPrime.toFixed(2),
      ponderalIndex: ponderalIndex.toFixed(2),
    };
  }

  const handleCalculations = (e) => {
    e.preventDefault();
    setAngle(-88)
    const ageValue = Number(age);
    const isValidAge = ageValue >= 2 && ageValue <= 120;

    if (!isValidAge) {
      setError((prevError) => ({
        ...prevError,
        age: 'Age must be between 2 and 120',
      }));
      return;
    }

    // Adjust height based on the selected measurement unit
    let heightInMeters = height;
    if (hightMesurement === 0) { // If height is in cm
      heightInMeters = height / 100;
    }

    // Adjust weight based on the selected measurement unit
    let weightInKg = weight;
    if (weightMesurement === 1) { // If weight is in pounds
      weightInKg = weight / 2.20462; // Convert pounds to kilograms
    }

    // Validate height and weight
    if (!heightInMeters || !weightInKg) {
      setError((prevError) => ({
        ...prevError,
        height: heightInMeters ? '' : 'Invalid height',
        weight: weightInKg ? '' : 'Invalid weight',
      }));
      return;
    }

    // Calculate BMI
    const bmiValue = weightInKg / (heightInMeters ** 2);

    if (bmiValue) {
      let data = calculateBMIValues(heightInMeters, weightInKg);
      if (data?.bmi) {
        setResult(data)
      }
      setBmi(bmiValue.toFixed(1));
      determineBMIAngle(bmiValue);
    }

    // Clear age error if no issues
    if (error.age) {
      setError((prevError) => ({
        ...prevError,
        age: '',
      }));
    }
  };

  // Function to calculate the angle based on BMI
  const determineBMIAngle = (bmiValue) => {
    debugger
    let angle;
    if (bmiValue < 16) {
      angle = -83;
    } else if (bmiValue < 18.5) {
      angle = -75 + ((bmiValue - 16) / (18.5 - 16)) * 30;
    } else if (bmiValue >= 18.5 && bmiValue < 25) {
      angle = -50 + ((bmiValue - 18.5) / (25 - 18.5)) * 30;
    } else if (bmiValue >= 25 && bmiValue < 30) {
      angle = -17 + ((bmiValue - 25) / (30 - 25)) * 30;
    } else if (bmiValue >= 30 && bmiValue < 40) {
      angle = 15 + ((bmiValue - 30) / (40 - 30)) * 30;
    } else {
      angle = 90;
    }
    setTimeout(() => {
      setAngle(angle);
    }, 1000)
  };

  // const determineBMIAngle = (bmiValue) => {
  //   let angle;

  //   if (gender === "Male") {
  //     if (bmiValue < 18.5) {
  //       angle = -45 + ((bmiValue - 16) / (18.5 - 16)) * 30;
  //     } else if (bmiValue >= 18.5 && bmiValue < 25) {
  //       angle = -50 + ((bmiValue - 18.5) / (25 - 18.5)) * 30;
  //     } else if (bmiValue >= 25 && bmiValue < 30) {
  //       angle = -17 + ((bmiValue - 25) / (30 - 25)) * 30;
  //     } else if (bmiValue >= 30 && bmiValue < 40) {
  //       angle = 15 + ((bmiValue - 30) / (40 - 30)) * 30;
  //     } else {
  //       angle = 75;
  //     }
  //   } else if (gender === "Female") {
  //     if (bmiValue < 18.5) {
  //       angle = -50 + ((bmiValue - 15) / (18.5 - 15)) * 30;
  //     } else if (bmiValue >= 18.5 && bmiValue < 24) {
  //       angle = -40 + ((bmiValue - 18.5) / (24 - 18.5)) * 30;
  //     } else if (bmiValue >= 24 && bmiValue < 29) {
  //       angle = -10 + ((bmiValue - 24) / (29 - 24)) * 30;
  //     } else if (bmiValue >= 29 && bmiValue < 39) {
  //       angle = 20 + ((bmiValue - 29) / (39 - 29)) * 30;
  //     } else {
  //       angle = 80;
  //     }
  //   }
  //   // Apply the calculated angle after a delay
  //   setTimeout(() => {
  //     setAngle(angle);
  //   }, 1000);
  // };

  const resetForm = () => {
    setAge("");
    setGender("Male");
    setHeight("");
    setWeight("");
    setBmi(null);
    setAngle(0);
    setResult({})
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <SEO
        title="BMI Calculator: Check Your Body Mass Index"
        description="Check your Body Mass Index to see if you're underweight, overweight, or in a healthy range using our BMI calculator for your height and age."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/bmi-calculator"
      />
      <Layout>

        <div className="container">
          <section className="content-cover-box">
            <div className="calculate-percentage">
              {/* Percentage Calculator  */}

              <div className="">
                <h1 className="primary-font mt-0 mb-1">
                  BMI(Body Mass Index) Calculator
                </h1>
                <div className="calculate-percentage-label">
                  Adjust the values and press the calculate button to see the
                  results
                </div>
                <div className="calculate-card">
                  <div className="bmi-calculate">
                    <div className="mbi-calculate-left">
                      <form onSubmit={handleCalculations}>
                        <div className="mbi-calculate-row">
                          <div className="mbi-label">
                            <p>Age</p>
                          </div>
                          <div className="d-flex align-items-center gap-10">
                            <input type="text"
                              value={age}
                              maxLength={3}
                              required
                              onChange={(e) => handleAgeInput(e)}
                            />
                            <div className="bmi-age-count">
                              <p>Ages: 2-120</p>
                            </div>
                          </div>
                        </div>
                        {error.age && <p className="error" >{error.age}</p>}

                        <div className="mbi-calculate-row">
                          <div className="mbi-label">
                            <p>Gender</p>
                          </div>
                          <div className="d-flex align-items-center gap-10">
                            <div className="gender-select">
                              <input type="radio" value="Male" checked={gender === "Male"} onChange={() => setGender("Male")} />
                              <span>Male</span>
                            </div>
                            <div className="gender-select">
                              <input type="radio" value="Female" checked={gender === "Female"} onChange={() => setGender("Female")} />
                              <span>Female</span>
                            </div>
                          </div>
                        </div>
                        <div className="mbi-calculate-row">
                          <div className="mbi-label">
                            <p>Hight</p>
                          </div>
                          <div className="d-flex align-items-center gap-10">
                            <input type="text" value={height}
                              maxLength={6}
                              required
                              onChange={(event) => {
                                const { value } = event.target;
                                if (isInputValueValid(value)) {
                                  setHeight(value)
                                }
                              }}
                              placeholder={`in ${hightMesurement ? 'meter' : 'cm'}`} />
                            <div className="">
                              <select value={hightMesurement} onChange={(e) => setHightMesurement(+e.target.value)}>
                                <option value={0}>Cm</option>
                                <option value={1}>Meter</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="mbi-calculate-row">
                          <div className="mbi-label">
                            <p>Weight </p>
                          </div>
                          <div className="d-flex align-items-center gap-10">
                            <input type="text" value={weight}
                              required
                              maxLength={6}
                              onChange={(event) => {
                                const { value } = event.target;
                                if (isInputValueValid(value)) {
                                  setWeight(value)
                                }
                              }}
                              placeholder={`in ${weightMesurement ? 'pound' : 'kg'}`} />
                            <div className="">
                              <select onChange={(e) => { setWeightMesurement(+e.target.value) }}>
                                <option value={0}>Kg</option>
                                <option value={1}>Pound</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="calculate-btns">
                          <button type="submit" className="btn-theme">Calculate</button>
                          <button type="button" onClick={resetForm} className="btn-theme-outline">Clear</button>
                        </div>
                      </form>
                    </div>
                    {/* <div className="mbi-calculate-right">  */}
                    {bmi && (
                      <div className="bmi-result">
                        <h3>BMI = {bmi} kg/m²</h3>
                        <div>
                          <CustomSVG angle={angle} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {result?.bmi && <div className="container">
                <ul className="bmi-range">
                  <li>Healthy BMI range: 18.5 kg/m2 - 24.9 kg/m2</li>
                  <li>Healthy weight for the height: {result?.healthyWeightRange}</li>
                  <li>BMI Prime: {result?.bmiPrime}</li>
                  <li>Ponderal Index: {result?.ponderalIndex} kg/m3</li>
                </ul>
              </div>}
              {/* <div className="container"> */}
              <div className="share-link-calculator">
                <div className="">
                  <input
                    type="text"
                    value="https:// .flaticon.com /free-icon/sh are_182895"
                  />
                  <p>Copy and share this link</p>
                </div>
                <button className="share-link-btn">
                  Share {Icons.ShareBtnIcon}
                </button>
              </div>
              {/* </div> */}

              <p>
                One of the most important tools for our wellness journey is the
                Body Mass Index(BMI) calculator. It helps an individual understand
                ways to maintain a healthy body weight based on their height to
                stay healthy. It serves as a key to managing body health in
                today’s health-conscious world.
              </p>
              <p>
                A quick way to categorize an individual as underweight, normal
                weight, overweight, or obese. So, how do you know whether you fall
                in the healthy weight range or not?
              </p>
              <h2 className="primary-font">How to Calculate BMI?</h2>
              <p>
                You can calculate it using two different formulas, either the
                metric or the imperial system.
              </p>
              <div className="container">
                <ul className="bmi-range">
                  <li>
                    <b>Metric System </b>
                    <br />
                    Here, it is calculated by dividing weight in kilograms with
                    height in square meters. Measure your weight and height,
                    square your height, and then divide your weight by this
                    squared value to find your BMI.
                  </li>
                  <li>
                    <b>Imperial System</b>
                    <br />
                    Here, BMI is found by dividing your weight in pounds by your
                    height in inches squared, then multiplying by 703. To
                    calculate your body mass index, first measure your weight and
                    height to square your height. In addition, divide your weight
                    by this squared height, and multiply the result by 703.
                  </li>
                </ul>
              </div>

              <h2 className="primary-font">What is Normal BMI?</h2>
              <p>
                A normal body mass index falls between 18.5 and 25. BMI falling
                between 25 and 30, considers you overweight, while over 30 will
                classify you as obese. Also, a BMI below 18.5 indicates you are
                underweight.
              </p>

              <h2 className="primary-font">What are the various BMI ranges?</h2>
              <p>
                For adults over the age of 20 years, we group these values into
                the following weight status categories. Let’s look at a few
                categories ahead:
              </p>

              <div class="table-container">
                <table>
                  <tr>
                    <th>BMI</th>
                    <th>Weight Status</th>
                  </tr>
                  <tr>
                    <td>Below 18.5</td>
                    <td>Underweight</td>
                  </tr>
                  <tr>
                    <td>18.5-24.9</td>
                    <td>Normal weight</td>
                  </tr>
                  <tr>
                    <td>25-29.9</td>
                    <td>Overweight</td>
                  </tr>
                  <tr>
                    <td>30-35</td>
                    <td>Obese</td>
                  </tr>
                  <tr>
                    <td>Over 35</td>
                    <td>Morbid Obesity</td>
                  </tr>
                </table>
              </div>

              <h2 className="primary-font">Purpose of BMI</h2>
              <p>
                BMI provides a straightforward way to categorize individuals based
                on their weight status. It can be useful in identifying potential
                health risks associated with being underweight, overweight, or
                obese. It is especially valuable in public health settings for
                tracking weight trends in populations. Also, It assesses the
                effectiveness of weight management programs.
              </p>

              <h2 className="primary-font">Track BMI with ‘Calcuvate’ </h2>
              <p>
                ‘Calcuvate’ is a free online tool that performs a lot of
                mathematical calculations based on various functions including
                Body Mass Index (BMI). Our objective is to save your precious time
                used in tracking health. Our team of experts delivers quick
                responses and convenient access with guaranteed accuracy.
                Additionally, it is the method to gauge whether your weight is in
                healthy proportion to your height. This helps to know your overall
                health status. Our Free BMI Calculator includes an in-depth
                explanation of body fat as well as muscle mass. It helps users to
                understand what the results tell about their health. Thus, you can
                use it as an educational resource too.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
