import React from "react";
import Layout from "../component/Layout";
import SEO from "../component/SEO";
import PercentageCalc from "../component/Calculators/PercenTage/PercentageCalc";
import PercentageCmmnPhraseCalc from "../component/Calculators/PercenTage/PercentageCmmnPhraseCalc";
import PercentageDifferenceCalc from "../component/Calculators/PercenTage/PercentageDifferenceCalc";
import PercentageChangeCalc from "../component/Calculators/PercenTage/PercentageChangeCalc";

export default function PercentageCalculator() {
  React.useEffect(() => { window.scrollTo(0, 0); }, [])

  return (
    <>
      <SEO
        title="Free Online Percentage Calculator - Calcuvate"
        description="Calcuvate's Percentage Calculator eases your percentage calculations. It is fast, accurate, and user-friendly for many purposes!"
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/percentage-calculator"
      />
      <Layout>
        <div className="container">
          <section className="content-cover-box">
            <div className="calculate-percentage">

              {/* Percentage Calculator  */}
              <PercentageCalc />
              {/* Percentage Calculator in Common Phrases */}

              {/* <div className="">
                <h2 className="primary-font">
                  Percentage Calculator in Common Phrases{" "}
                </h2>
                <div className="calculate-card">
                  <div className="calculate-row">
                    <div className="">
                      <p>What is</p>
                    </div>
                    <input type="text" className="ad-percentage" />
                    <div className="">
                      <p>Of</p>
                    </div>
                    <input type="text" />
                    <div className="">
                      <p>=</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-btns">
                    <button className="btn-theme">Calculate</button>
                    <button className="btn-theme-outline">Clear</button>
                  </div>
                </div>
                <div className="calculate-card">
                  <div className="calculate-row">
                    <input type="text" />

                    <div className="">
                      <p>Is What</p>
                    </div>
                    <div className="">
                      <p>% </p>
                    </div>
                    <div className="">
                      <p>Of</p>
                    </div>
                    <input type="text" />
                    <div className="">
                      <p>=</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-btns">
                    <button className="btn-theme">Calculate</button>
                    <button className="btn-theme-outline">Clear</button>
                  </div>
                </div>
                <div className="calculate-card">
                  <div className="calculate-row">
                    <input type="text" />

                    <div className="">
                      <p>Of</p>
                    </div>
                    <input type="text" className="ad-percentage" />
                    <div className="">
                      <p>Of What</p>
                    </div>
                    <div className="">
                      <p>=</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-btns">
                    <button className="btn-theme">Calculate</button>
                    <button className="btn-theme-outline">Clear</button>
                  </div>
                </div>
                <div className="calculate-card">
                  <div className="result-box-contin">
                    <div className="result-box">Result: 12</div>

                    <p>Result: 41.071428571429</p>
                    <div className="result-share">
                      <div className="">
                        <p className="mt-0 mb-0">Steps:</p>
                        <p className="mb-0 mt-0">23 ÷ 56% = 41.071428571429%</p>
                      </div>
                      {Icons.ShareIcon}
                    </div>
                  </div>
                </div>
              </div> */}
              <PercentageCmmnPhraseCalc />

              {/* Percentage Calculator in Common Phrases */}
              {/* <div className="">
                <h2 className="primary-font">
                  Percentage Calculator in Common Phrases
                </h2>
                <div className="calculate-card">
                  <div className="calculate-row">
                    <div className="">
                      <p>Value 1</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-row">
                    <div className="">
                      <p>Value 2</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-btns">
                    <button className="btn-theme">Calculate</button>
                    <button className="btn-theme-outline">Clear</button>
                  </div>
                </div>
                <div className="calculate-card">
                  <div className="result-box-contin">
                    <div className="result-box">Result: 39.285714285714</div>

                    <p>Difference of 67 and 45 are 39.285714285714%</p>

                    <div className="">
                      <p className="mt-0 mb-0">Steps:</p>
                      <p className="mb-0 mt-0">
                        Difference of 67 and 45 =  |67 - 45| / (67 + 45)2 = 22/56
                      </p>
                      <p className="mb-0 mt-0">=0.39285714285714</p>
                      <p className="mb-0 mt-0"> =39.285714285714%</p>
                    </div>
                    <p>45 is a 32.835820895522% decrease of 67.</p>

                    <div className="result-share">
                      <div className="">
                        <p className="mt-0 mb-0">Steps:</p>
                        <p className="mb-0 mt-0">
                          Percentage of decrease =|67 – 45|/67 =22/67
                        </p>
                        <p className="mt-0 mb-0">=0.32835820895522</p>
                        <p className="mt-0 mb-0">=32.835820895522%</p>
                      </div>
                      {Icons.ShareIcon}
                    </div>
                  </div>
                </div>
              </div> */}
              <PercentageDifferenceCalc />

              {/* Percentage Calculator */}
              {/* <div className="">
                <h2 className="primary-font">Percentage Calculator</h2>
                <div className="calculate-card">
                  <div className="calculate-row">
                    <input type="text" />
                    <select>
                      <option>Increase</option>
                      <option>Increase</option>
                    </select>
                    <input type="text" className="ad-percentage" />

                    <div className="">
                      <p>=</p>
                    </div>
                    <input type="text" />
                  </div>
                  <div className="calculate-btns">
                    <button className="btn-theme">Calculate</button>
                    <button className="btn-theme-outline">Clear</button>
                  </div>
                </div>
                <div className="calculate-card">
                  <div className="result-box-contin">
                    <div className="result-box">Result: 30.36</div>

                    <p>23 increase 32% = 30.36</p>
                    <div className="result-share">
                      <div className="">
                        <p className="mt-0 mb-0">Steps:</p>
                        <p className="mb-0 mt-0">12% of 100 = 0.12 × 100 = 12</p>
                        <p className="mb-0 mt-0">= 23 × (1 + 0.32) </p>
                        <p className="mb-0 mt-0">= 30.36</p>
                      </div>
                      {Icons.ShareIcon}
                    </div>
                  </div>
                </div>
              </div> */}
              <PercentageChangeCalc />

              <p>
                This online percentage calculator is a free tool that gives us the
                percentage of a specified number. It delivers the percentage in
                just a few seconds after entering the values in the boxes given. A
                valuable tool that helps save your important time.
              </p>

              <h2 className="primary-font">Percentage?</h2>
              <p>
                A percentage is a way to show how much value is compared to
                another, usually the original value. It is commonly used in
                business to calculate profit or loss. In schools and colleges,
                percentages help represent students' scores. Essentially, a
                percentage is a ratio of a value to the total value, multiplied by
                100. The symbol for percentage is %.
              </p>

              <h2 className="primary-font">formula</h2>
              <p>
                This calculator is a user-friendly tool that is measured by using
                a formula. The percentage formula is equal to the value divided by
                the total value multiplied by 100. It is highly used to calculate
                the profit and loss percentage of a business.
              </p>
              <p>
                <b>Percentage% = (Value / Total Value) x 100</b>
              </p>

              <h2 className="primary-font">Percentage Difference</h2>
              <p>
                To find the percentage difference between two values, you first
                calculate the absolute difference between the two numbers. Then,
                divide that difference by the average of the two numbers. Finally,
                multiply the result by 100 to get the answer in percent instead of
                decimal form. Here’s the formula for reference.
              </p>

              <p>
                <b>Please write using the mathematical formula–</b>
              </p>
              <p>
                <b>Percentage Difference = [( V1 - V2 ) / ( V1 + V2 )]/2 x 100</b>
              </p>

              <h2 className="primary-font">Percentage Change</h2>
              <p>
                To find the percentage increase or decrease, you start by
                calculating the difference between two values and then compare
                that difference to the initial value. This means you take the
                absolute difference and divide it by the initial value to see how
                much it has changed.
              </p>
              <p>
                It helps to identify an increase or decrease based on a specific
                percentage of the starting number. It works by converting the
                percentage into a decimal, then either subtracting (for a
                decrease) or adding (for an increase) this decimal from or to 1.
                Finally, you multiply the original number by this value to get the
                new number. Here’s an example for clarity.
              </p>
              <p>
                <b>Please change the calculation in the example–</b>
              </p>

              <p>
                <b> EX: 500 increased by 10% (0.1) 500 × (1 + 0.1) = 550</b>
              </p>
              <p>
                <b>500 decreased by 10% 500 × (1 – 0.1) = 450</b>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
