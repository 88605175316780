import React from "react";
import { Link } from "react-router-dom";
import Layout from "../component/Layout";
import Scientific from "../component/Calculators/Scientific";
import Icons from "../Assets/Icons";
import SEO from "../component/SEO";

export default function Home() {
  return (
    <>
      <SEO
        title="Calcuvate: Free Online Calculators - Math, Finance and More"
        description="Calcuvate offers Free Online Calculators for math, finance, fitness, and everyday use. Get accurate results quickly and easily for all your calculation needs."
        image="https://www.calcuvate.com/socials/social-icon.jpg"
        url="https://www.calcuvate.com/"
      />
      <Layout>
        <div className="container">
          <section className="banner-part">
            <div className="banner-main-text">
              <div>
                <h1 className="primary-font">
                  Free Online Calculators <br />
                  Hub for All Calculations
                </h1>
              </div>
              <h2>
                Get Fast and Accurate Calculations with Our Free Online Tools
              </h2>
            </div>
          </section>
        </div>
        <div className="main-calcu">
          <div className="container">
            <Scientific />
          </div>
        </div>
        <div className="container">
          <section className="banner-calcuvate">
            <div className="w-80">
              {/* <div className="calculator-img">
              <img
                src={require("../Assets/Images/banner-img-main.png")}
                alt=""
              />
            </div> */}
              <p>
                At &apos;Calcuvate&apos;, we offer a range of free online
                calculators to handle diverse calculations. Our comprehensive
                package of tools covers everything from finance and science to
                fitness tracking and geometry, simplifying your calculations. We
                feel committed to serving the community by saving your valuable
                time and effort.
              </p>
              <p>
                With our growing collection of calculators, we strive to deliver
                the fastest, most convenient results to our users. Our team of
                IT professionals has developed them with expertise and precision
                for your convenience. We dedicate ourselves to being your
                trusted resource for prompt and reliable calculations. Moreover,
                we believe that valuable information should be accessible to
                everyone. We aim to connect with more people worldwide to gather
                their valuable feedback.
              </p>
            </div>
          </section>
        </div>
        <div className="container ">
          <section className="calcuvate-category">
            <div className="calcuvate-category-box">
              <div className="category-single-box">
                <img src={require("../Assets/Images/fitness-img.png")} alt="Fitness Calculators" />
                <h2 className="primary-font">Fitness</h2>
                <p>
                  <Link to="/bmi-calculator">
                    BMI Calculator
                  </Link>
                </p>
                <p>
                  {/* <Link to="/bmr-calculator"> */}
                  BMR Calculator
                  {/* </Link> */}
                </p>
                <p>
                  {/* <Link to="/calorie-calculator"> */}
                  Calorie Calculator
                  {/* </Link> */}
                </p>
              </div>
              <div className="category-single-box">
                <img src={require("../Assets/Images/math-img.png")} alt="Math Calculators" />
                <h2 className="primary-font">math</h2>
                <p>Scientific Calculator</p>
                <Link to='/percentage-calculator'>
                  <p>Percentage Calculator</p>
                </Link>
                <p>Simple Interest Calculator</p>
              </div>
              <div className="category-single-box">
                <img
                  src={require("../Assets/Images/everyday-life-img.png")}
                  alt="Everyday Life Calculators"
                />
                <h2 className="primary-font">everyday life</h2>
                <p>Stopwatch</p>
                <p>Age Calculator</p>
                <p>Time Calculator</p>
                <p>Hours Calculator</p>
                <p>Date Calculator</p>
                <p>Conversion Calculator</p>
              </div>
              <div className="category-single-box">
                <img src={require("../Assets/Images/finance-img.png")} alt="Finance Calculators" />
                <h2 className="primary-font">finance</h2>
                <p>Loan Calculator</p>
                <p>Salary Calculator</p>
                <p>Interest Rate Calculator</p>
                <p>Mortgage Calculator</p>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <section className="our-blogs">
            <div className="blogs-box-contin">
              <div className="blogs-box-contin-row">
                <div className="blogs-box border-bottom border-right blog-main-text">
                  <div className="">
                    <h2 className="primary-font blog-head">Our Blogs</h2>
                  </div>
                </div>
                <div className="blogs-box border-bottom ">
                  <div className="blog-date">
                    Jennifer Brien<span>/03 Nov, 2024</span>
                  </div>
                  <h2>
                    Master Repayment Strategies With An Online Loan Calculator
                  </h2>
                  <p>
                    It can be a game changer on your finances since it would
                    give you clear insights into your loan payments and can help
                    you compare offers.
                  </p>
                  <Link to="master-repayment-strategies-with-an-online-loan-calculator">
                    Read More
                    {Icons.BlogArrow}
                  </Link>
                </div>
                <div className="blogs-box border-bottom  border-left">
                  <div className="blog-date">
                    Jennifer Brien<span>/13 Oct, 2024</span>
                  </div>
                  <h2>Manage Finances With Online Payroll Calculator</h2>
                  <p>
                    Handling multiple expenses together is a tough task for
                    every individual nowadays. Therefore, technology has become
                    an extremely important element to keep everything on track.
                  </p>
                  <Link to="/manage-finances-with-online-payroll-calculator">
                    Read More
                    {Icons.BlogArrow}
                  </Link>
                </div>
              </div>
              <div className="blogs-box-contin-row">
                <div className="blogs-box border-right">
                  <div className="blog-date">
                    Jennifer Brien<span>/03 Oct, 2024</span>
                  </div>
                  <h2>Tips for Using a Loan Calculator</h2>
                  <p>
                    It simplifies your calculations by assisting you in having a
                    better view of your financial commitment. Moreover, it
                    encourages you to make appropriate financial decisions.
                  </p>
                  <Link to="/tips-for-using-a-loan-calculator">
                    Read More
                    {Icons.BlogArrow}
                  </Link>
                </div>
                <div className="blogs-box">
                  <div className="blog-date">
                    Jennifer Brien<span>/25 Sep, 2024</span>
                  </div>
                  <h2>Use Salary Calculator: Calculate Your Worth</h2>
                  <p>
                    This online calculator helps you estimate your salary, net
                    income, or take-home pay after taxes and benefits. Our
                    easy-to-use website is open to everyone.
                  </p>
                  <Link to="/use-salary-calculator-calculate-your-worth">
                    Read More
                    {Icons.BlogArrow}
                  </Link>
                </div>
                <div className="blogs-box border-left">
                  <div className="blog-date">
                    Jennifer Brien<span>/10 Sep, 2024</span>
                  </div>
                  <h2>The Importance Of Online Scientific Calculator</h2>
                  <p>
                    It makes them valuable in subjects such as mathematics,
                    physics, and engineering. Moreover, these calculators solve
                    even more difficult equations.
                  </p>
                  <Link to="the-importance-of-online-scientific-calculator">
                    Read More
                    {Icons.BlogArrow}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}
