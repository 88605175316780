import React, { useState } from "react";
import Icons from "../../../Assets/Icons";
import { isInputValueValid } from "../../../utils/commonFiles";

const PercentageChangeCalc = () => {
    const [value1, setValue1] = useState("");
    const [percentage, setPercentage] = useState("");
    const [operation, setOperation] = useState("Increase");
    const [result, setResult] = useState("");
    const [steps, setSteps] = useState("");

    const handleCalculate = () => {
        if (value1 && percentage) {
            const baseValue = parseFloat(value1);
            const percentageValue = parseFloat(percentage) / 100;

            let calculatedValue = 0;
            let calculationSteps = "";

            if (operation === "Increase") {
                calculatedValue = baseValue * (1 + percentageValue);
                calculationSteps = `
          ${percentage}% of ${baseValue} = ${percentageValue} × ${baseValue}
          = ${baseValue} × (1 + ${percentageValue.toFixed(2)})
          = ${calculatedValue.toFixed(2)}
        `;
            } else if (operation === "Decrease") {
                calculatedValue = baseValue * (1 - percentageValue);
                calculationSteps = `
          ${percentage}% of ${baseValue} = ${percentageValue} × ${baseValue}
          = ${baseValue} × (1 - ${percentageValue.toFixed(2)})
          = ${calculatedValue.toFixed(2)}
        `;
            }

            setResult(
                `${baseValue} ${operation.toLowerCase()} ${percentage}% = ${calculatedValue.toFixed(2)}`
            );
            setSteps(calculationSteps);
        } else {
            setResult("Please enter valid inputs for the calculation!");
            setSteps("");
        }
    };

    const handleClear = () => {
        setValue1("");
        setPercentage("");
        setOperation("Increase");
        setResult("");
        setSteps("");
    };

    return (
        <div>
            <h2 className="primary-font">Percentage Change Calculator</h2>
            <div className="calculate-card">
                <div className="calculate-row">
                    <input
                        type="text"
                        value={value1}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setValue1(value)
                            }
                        }}
                        placeholder="Enter Value"
                    />
                    <select
                        value={operation}
                        onChange={(e) => setOperation(e.target.value)}
                    >
                        <option>Increase</option>
                        <option>Decrease</option>
                    </select>
                    <input
                        type="text"
                        value={percentage}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setPercentage(value)
                            }
                        }}
                        placeholder="Enter Percentage"
                        className="ad-percentage"
                    />
                    <div>
                        <p>=</p>
                    </div>
                    <input
                        type="text"
                        value={result}
                        readOnly
                        placeholder="Result"
                    />
                </div>
                <div className="calculate-btns">
                    <button className="btn-theme" onClick={handleCalculate}>
                        Calculate
                    </button>
                    <button className="btn-theme-outline" onClick={handleClear}>
                        Clear
                    </button>
                </div>
            </div>
            {result && (
                <div className="calculate-card">
                    <div className="result-box-contin">
                        <div className="result-box">Result: {result}</div>
                        <p>{result}</p>
                        <div className="">
                            <p className="mt-0 mb-0">Steps:</p>
                            <p className="mb-0 mt-0">{steps}</p>
                        </div>
                        <div className="result-share">
                            {Icons.ShareIcon}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PercentageChangeCalc;
