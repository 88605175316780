import React, { useState } from "react";
import { isInputValueValid } from "../../../utils/commonFiles";

const PercentageCmmnPhraseCalc = () => {
    const [input1, setInput1] = useState("");
    const [input2, setInput2] = useState("");
    const [result1, setResult1] = useState("");

    const [input3, setInput3] = useState("");
    const [input4, setInput4] = useState("");
    const [result2, setResult2] = useState("");

    const [input5, setInput5] = useState("");
    const [input6, setInput6] = useState("");
    const [result3, setResult3] = useState("");

    const calculate1 = () => {
        if (input1 && input2) {
            const value = (parseFloat(input1) / 100) * parseFloat(input2);
            setResult1(value.toFixed(2));
        }
    };

    const calculate2 = () => {
        if (input3 && input4) {
            const value = (parseFloat(input3) / parseFloat(input4)) * 100;
            setResult2(value.toFixed(2));
        }
    };

    const calculate3 = () => {
        if (input5 && input6) {
            const value = parseFloat(input5) / (parseFloat(input6) / 100);
            setResult3(value.toFixed(2));
        }
    };

    const clearInputs = () => {
        setInput1("");
        setInput2("");
        setResult1("");

        setInput3("");
        setInput4("");
        setResult2("");

        setInput5("");
        setInput6("");
        setResult3("");
    };

    return (
        <div className="percentage-calculator">
            <h2 className="primary-font">Percentage Calculator in Common Phrases</h2>

            {/* First Row */}
            <div className="calculate-card">
                <div className="calculate-row">
                    <p>What is</p>
                    <input
                        type="text"
                        value={input1}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput1(value)
                            }
                        }}
                        placeholder="%"
                    />
                    <p>of</p>
                    <input
                        type="text"
                        value={input2}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput2(value)
                            }
                        }}
                        placeholder="Value"
                    />
                    <p>=</p>
                    <input type="text" readOnly value={result1} placeholder="Result" />
                </div>
                <div className="calculate-btns">
                    <button onClick={calculate1} className="btn-theme">
                        Calculate
                    </button>
                    <button onClick={clearInputs} className="btn-theme-outline">
                        Clear
                    </button>
                </div>
            </div>

            {/* Second Row */}
            <div className="calculate-card">
                <div className="calculate-row">
                    <input
                        type="text"
                        value={input3}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput3(value)
                            }
                        }}
                        placeholder="Value"
                    />
                    <p>is what</p>
                    <p>%</p>
                    <p>of</p>
                    <input
                        type="text"
                        value={input4}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput4(value)
                            }
                        }}
                        placeholder="Value"
                    />
                    <p>=</p>
                    <input type="text" readOnly value={result2} placeholder="Result" />
                </div>
                <div className="calculate-btns">
                    <button onClick={calculate2} className="btn-theme">
                        Calculate
                    </button>
                    <button onClick={clearInputs} className="btn-theme-outline">
                        Clear
                    </button>
                </div>
            </div>

            {/* Third Row */}
            <div className="calculate-card">
                <div className="calculate-row">
                    <input
                        type="text"
                        value={input5}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput5(value)
                            }
                        }}
                        placeholder="Value"
                    />
                    <p>of</p>
                    <input
                        type="text"
                        value={input6}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (isInputValueValid(value)) {
                                setInput6(value)
                            }
                        }}
                        placeholder="%"
                    />
                    <p>of what</p>
                    <p>=</p>
                    <input type="text" readOnly value={result3} placeholder="Result" />
                </div>
                <div className="calculate-btns">
                    <button onClick={calculate3} className="btn-theme">
                        Calculate
                    </button>
                    <button onClick={clearInputs} className="btn-theme-outline">
                        Clear
                    </button>
                </div>
            </div>

            {/* Results */}
            {(result1 || result2 || result3) && <div className="calculate-card">
                <div className="result-box-contin">
                    <div className="result-box">Result: {result1 || result2 || result3}</div>
                    <p>Steps:</p>
                    {result1 && (
                        <p>{`${input1}% of ${input2} = ${(input1 / 100).toFixed(2)} × ${input2} = ${result1}`}</p>
                    )}
                    {result2 && (
                        <p>{`${input3} ÷ ${input4} × 100 = ${result2}%`}</p>
                    )}
                    {result3 && (
                        <p>{`${input5} ÷ (${input6} / 100) = ${result3}`}</p>
                    )}
                </div>
            </div>}
        </div>
    );
};

export default PercentageCmmnPhraseCalc;